import { ExternalLink, Text, Title } from "@clipboard-health/ui-react";
import { Li, Ul, type UseModalState } from "@clipboard-health/ui-react";
import { DialogContent, Stack } from "@mui/material";
import { type HcpRequirement } from "@src/appV2/Accounts/Documents/types";
import { type VisitMissingRequirements } from "@src/appV2/ExperimentalHomeHealth/types";
import { BottomSheet } from "@src/appV2/redesign/components/BottomSheet";
import { Button } from "@src/appV2/redesign/components/Button";
import { DialogFooter } from "@src/appV2/redesign/components/DialogFooter";
import { Illustration } from "@src/appV2/redesign/components/Illustration";
import { TextButton } from "@src/appV2/redesign/components/TextButton";
import { STATE_LICENSE_REQUIREMENT_ID } from "@src/constants";
import { isEmpty } from "lodash";
import pluralize from "pluralize";

interface Props {
  missingDocuments: HcpRequirement[];
  expiringDocuments: HcpRequirement[];
  pendingDocuments: HcpRequirement[];
  modalState: UseModalState;
  onConfirm: () => void;
}

const MAX_SHOWN_DOCUMENTS_PER_SECTION = 6;

// This deviates from the design in the figma, but temporarily using this for now to
// unblock the missing documents flow. Most of this was copied from
// https://github.com/ClipboardHealth/cbh-mobile-app/blob/main/src/appV2/ExperimentalHomeHealth/FindCases/MissingDocumentsDialog.tsx
// but changed slightly to leverage the new design system
export function LegacyMissingDocumentsDialog(props: Props) {
  const { missingDocuments, expiringDocuments, pendingDocuments, modalState, onConfirm } = props;

  const visibleMissingDocuments: VisitMissingRequirements[] = [];
  const visibleExpiringDocuments: VisitMissingRequirements[] = [];
  const invisibleMissingDocuments: VisitMissingRequirements[] = [];

  missingDocuments.forEach((requirement) => {
    if (requirement.reqId === STATE_LICENSE_REQUIREMENT_ID) {
      visibleMissingDocuments.push({
        ...requirement,
        name: "State License (upload as Miscellaneous)",
      });
    } else if (requirement.visibleToHCP) {
      visibleMissingDocuments.push(requirement);
    } else {
      invisibleMissingDocuments.push(requirement);
    }
  });

  expiringDocuments.forEach((requirement) => {
    if (requirement.reqId === STATE_LICENSE_REQUIREMENT_ID) {
      visibleExpiringDocuments.push({
        ...requirement,
        name: "State License (upload as Miscellaneous)",
      });
    } else if (requirement.visibleToHCP) {
      visibleExpiringDocuments.push(requirement);
    } else {
      invisibleMissingDocuments.push(requirement);
    }
  });

  const hasMoreDocuments =
    visibleMissingDocuments.length > MAX_SHOWN_DOCUMENTS_PER_SECTION ||
    visibleExpiringDocuments.length > MAX_SHOWN_DOCUMENTS_PER_SECTION ||
    invisibleMissingDocuments.length > MAX_SHOWN_DOCUMENTS_PER_SECTION;

  function getDialogTitle() {
    if (!isEmpty(missingDocuments) && !isEmpty(expiringDocuments)) {
      return `You have ${missingDocuments.length + expiringDocuments.length} ${pluralize(
        "document",
        missingDocuments.length + expiringDocuments.length
      )} missing or expiring soon`;
    }

    if (!isEmpty(missingDocuments)) {
      return `You're missing ${missingDocuments.length} ${pluralize(
        "document",
        missingDocuments.length
      )}`;
    }

    if (!isEmpty(expiringDocuments)) {
      return `You have ${expiringDocuments.length} expiring ${pluralize(
        "document",
        expiringDocuments.length
      )}`;
    }

    if (!isEmpty(pendingDocuments)) {
      return `You have ${pendingDocuments.length} pending ${pluralize(
        "document",
        pendingDocuments.length
      )}`;
    }

    return "You have missing requirements";
  }

  return (
    <BottomSheet
      modalState={modalState}
      footer={
        <DialogFooter orientation="vertical">
          {(visibleMissingDocuments.length > 0 || visibleExpiringDocuments.length > 0) && (
            <Button fullWidth variant="contained" onClick={onConfirm}>
              Upload Docs
            </Button>
          )}
          <TextButton
            onClick={() => {
              modalState.closeModal();
            }}
          >
            Close
          </TextButton>
        </DialogFooter>
      }
    >
      <DialogContent>
        <Stack alignItems="center" spacing={8}>
          <Illustration type="document-upload" />
          <Title variant="h3" component="h3" sx={{ textAlign: "center" }}>
            {getDialogTitle()}
          </Title>
          <Stack spacing={2}>
            {visibleMissingDocuments.length > 0 && (
              <Stack>
                <Text>
                  To complete your booking, the following{" "}
                  {pluralize("document", missingDocuments.length)} must be uploaded and approved:
                </Text>
                <Ul sx={{ listStylePosition: "inside" }}>
                  {visibleMissingDocuments
                    .slice(0, MAX_SHOWN_DOCUMENTS_PER_SECTION)
                    .map((document) => (
                      <Li key={document.reqId}>
                        <Text semibold display="inline">
                          {document.name}
                        </Text>
                      </Li>
                    ))}
                </Ul>
              </Stack>
            )}
            {visibleExpiringDocuments.length > 0 && (
              <Stack>
                <Text>
                  The following {pluralize("document", visibleExpiringDocuments.length)} will expire
                  before this shift and must be uploaded and approved before you can book:
                </Text>
                <Ul sx={{ listStylePosition: "inside" }}>
                  {visibleExpiringDocuments
                    .slice(0, MAX_SHOWN_DOCUMENTS_PER_SECTION)
                    .map((document) => (
                      <Li key={document.reqId}>
                        <Text semibold display="inline">
                          {document.name}
                        </Text>
                      </Li>
                    ))}
                </Ul>
              </Stack>
            )}
            {invisibleMissingDocuments.length > 0 && (
              <Stack>
                <Text>
                  We will process the following requirements for you. Please contact{" "}
                  {/** we're temporarily using <ExternalLink> since this is a legacy view
                   * New components should leverage <TextButton> instead
                   */}
                  <ExternalLink to="mailto:onboarding@clipboardhealth.com">
                    onboarding@clipboardhealth.com
                  </ExternalLink>{" "}
                  if you have any questions:
                </Text>
                <Ul sx={{ listStylePosition: "inside" }}>
                  {invisibleMissingDocuments
                    .slice(0, MAX_SHOWN_DOCUMENTS_PER_SECTION)
                    .map((document) => (
                      <Li key={document.reqId}>
                        <Text semibold display="inline">
                          {document.name}
                        </Text>
                      </Li>
                    ))}
                </Ul>
              </Stack>
            )}
            {pendingDocuments.length > 0 &&
              isEmpty(missingDocuments) &&
              isEmpty(expiringDocuments) && (
                <Stack>
                  <Text sx={{ textWrap: "pretty" }}>
                    We are processing the following requirements for you. Please contact
                    <br />
                    <ExternalLink to="mailto:onboarding@clipboardhealth.com">
                      onboarding@clipboardhealth.com
                    </ExternalLink>{" "}
                    if you have any questions:
                  </Text>
                  <Ul sx={{ listStylePosition: "inside" }}>
                    {pendingDocuments.slice(0, MAX_SHOWN_DOCUMENTS_PER_SECTION).map((document) => (
                      <Li key={document.reqId}>
                        <Text semibold display="inline">
                          {document.name}
                        </Text>
                      </Li>
                    ))}
                  </Ul>
                </Stack>
              )}
            {hasMoreDocuments && (
              <Text align="center">
                Click to upload your documents and view the remaining missing requirements.
              </Text>
            )}
          </Stack>
        </Stack>
      </DialogContent>
    </BottomSheet>
  );
}
