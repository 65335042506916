import { Pill } from "@src/appV2/redesign/components/Pill";
import pluralize from "pluralize";

interface ShiftDocumentsPillProps {
  missingDocumentsCount: number;
  /**
   * If true, the pill will be compact and show a shorter label
   */
  compact?: boolean;
}

export function ShiftDocumentsPill(props: ShiftDocumentsPillProps) {
  const { missingDocumentsCount, compact = false } = props;

  return (
    <Pill
      size="medium"
      label={
        compact
          ? missingDocumentsCount
          : `${missingDocumentsCount} ${pluralize("document", missingDocumentsCount)} needed`
      }
      backgroundColor={(theme) => theme.palette.intent?.neutral.background}
      color={(theme) => theme.palette.intent?.neutral.text}
      iconType={compact ? "document" : undefined}
    />
  );
}
