import { Pill } from "@src/appV2/redesign/components/Pill";

export function QuickSignPill() {
  return (
    <Pill
      size="medium"
      label="Quick Sign"
      backgroundColor={(theme) => theme.palette.intent?.success.background}
      color={(theme) => theme.palette.intent?.success.text}
    />
  );
}
