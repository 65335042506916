import { Text, Title, type UseModalState } from "@clipboard-health/ui-react";
import { DialogContent, Stack } from "@mui/material";
import { DocumentStatus } from "@src/appV2/Accounts/Documents/types";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { BookabilityDecision, type BookabilityStatusItem } from "@src/appV2/OpenShifts/ShiftAction";
import { BottomSheet } from "@src/appV2/redesign/components/BottomSheet";
import { Button } from "@src/appV2/redesign/components/Button";
import { DialogFooter } from "@src/appV2/redesign/components/DialogFooter";
import { Illustration } from "@src/appV2/redesign/components/Illustration";
import {
  SHIFT_DISCOVERY_SHIFT_HELLO_SIGN_CONTAINER_MODAL_PATH,
  SHIFT_DISCOVERY_SHIFT_MODAL_PATH,
} from "@src/appV2/redesign/ShiftDiscovery/paths";
import { type DocumentSubmittedModalRoutePathParams } from "@src/appV2/redesign/ShiftDiscovery/types";
import { useShiftModalsDataContext } from "@src/appV2/redesign/ShiftDiscovery/useShiftModalsDataContext";
import pluralize from "pluralize";
import { useParams } from "react-router-dom";

import { ShiftBottomSheetSkeleton } from "../../../BottomSheet/SheetSkeleton";
import { type ShiftWithType } from "../../../types";
import { useDocumentSubmittedBottomSheet } from "./useDocumentSubmittedBottomSheet";

interface InstantReviewShiftDocumentSubmittedBottomSheetProps {
  modalState: UseModalState;
  bookabilityStatus?: BookabilityStatusItem;
  shift?: ShiftWithType;
}

export function InstantReviewShiftDocumentSubmittedBottomSheet(
  props: InstantReviewShiftDocumentSubmittedBottomSheetProps
) {
  const { modalState, bookabilityStatus, shift } = props;

  const { requirementId, documentStatus } = useParams<DocumentSubmittedModalRoutePathParams>();
  const { navigateToModal } = useShiftModalsDataContext();

  const isDocumentApproved = documentStatus === DocumentStatus.APPROVED;

  const {
    missingDocumentsSet,
    expiringDocumentsSet,
    pendingDocumentsSet,
    instantReviewBlockingDocuments,
    missingAndExpiringDocumentsCount,
    areAllBlockingDocumentsInstantReview,
    isAnyBlockingDocumentInstantReview,
    isMissingDocumentsLoading,
    isFetchingMissingRequirements,
  } = useDocumentSubmittedBottomSheet({
    shift,
    bookabilityStatus,
  });

  function getContent() {
    if (missingAndExpiringDocumentsCount > 0) {
      return `Complete ${missingAndExpiringDocumentsCount} ${
        areAllBlockingDocumentsInstantReview ? "instant review" : "more"
      } ${pluralize("document", missingAndExpiringDocumentsCount)} before confirming your shift.`;
    }

    if (pendingDocumentsSet.size > 0) {
      return `You have documents that are pending review before you can book this shift.`;
    }

    if (bookabilityStatus?.attributes.bookability.decision === BookabilityDecision.ALLOWED) {
      return "You can proceed to book your shift.";
    }

    return "Please complete the additional requirements to book this shift.";
  }

  return (
    <BottomSheet
      modalState={modalState}
      footer={
        <DialogFooter
          orientation="vertical"
          onClose={() => {
            modalState.closeModal();
          }}
        >
          {!isMissingDocumentsLoading && !isFetchingMissingRequirements && (
            <Button
              fullWidth
              size="large"
              onClick={() => {
                logEvent(
                  APP_V2_USER_EVENTS.SHIFT_BOOKING_FLOW_DOCUMENT_SUBMISSION_REVIEW_CTA_CLICKED,
                  {
                    shiftId: shift?._id ?? "",
                    submittedRequirementId: requirementId,
                    submittedDocumentStatus: documentStatus,
                    remainingMissingDocumentsCount: missingDocumentsSet.size,
                    remainingExpiringDocumentsCount: expiringDocumentsSet.size,
                    isNextDocumentInstantReview: isAnyBlockingDocumentInstantReview,
                    remainingInstantReviewDocumentsCount: instantReviewBlockingDocuments.length,
                    nextInstantReviewDocumentRequirementId: isAnyBlockingDocumentInstantReview
                      ? instantReviewBlockingDocuments[0].reqId
                      : undefined,
                  }
                );
                if (isAnyBlockingDocumentInstantReview) {
                  navigateToModal(
                    SHIFT_DISCOVERY_SHIFT_HELLO_SIGN_CONTAINER_MODAL_PATH,
                    {
                      requirementId: instantReviewBlockingDocuments[0].reqId,
                      shiftId: shift?._id ?? "",
                    },
                    true
                  );
                } else {
                  navigateToModal(
                    SHIFT_DISCOVERY_SHIFT_MODAL_PATH,
                    {
                      shiftId: shift?._id ?? "",
                    },
                    true
                  );
                }
              }}
            >
              {isAnyBlockingDocumentInstantReview ? "Complete next document" : "Continue"}
            </Button>
          )}
        </DialogFooter>
      }
      paperSx={{
        minHeight: (theme) => theme.size!.bottomSheet.review.minHeight,
      }}
    >
      {isMissingDocumentsLoading || isFetchingMissingRequirements ? (
        <ShiftBottomSheetSkeleton />
      ) : (
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Stack alignItems="center" spacing={8}>
            <Illustration type={isDocumentApproved ? "document-approved" : "document-review"} />
            <Title variant="h2" component="h3" sx={{ textWrap: "balance", textAlign: "center" }}>
              {isDocumentApproved ? "Your document is approved!" : "Your document is under review"}
            </Title>
            <Text align="center" paddingX={4} variant="body1">
              {getContent()}
            </Text>
          </Stack>
        </DialogContent>
      )}
    </BottomSheet>
  );
}
